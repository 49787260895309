<template>
  <div>
    <div>
      {{ label }}
      <span v-if="required" class="required">*</span>
    </div>
    <div :class="['switch-container', `switch-container--${this.size}`, customClass]">
      <div v-if="important" class="important">{{ `!` }}</div>
      <slot name="trueLabel">{{ trueLabel }}</slot>
      <label :class="['switch', `switch--${this.size}`]">
        <input
          type="checkbox"
          :id="id"
          v-model="localValue"
          :disabled="disabled"
          :data-test="dataAttr ? dataAttr : id"
        />
        <span :class="sliderClass"/>
      </label>
      <slot name="falseLabel">{{ falseLabel }}</slot>
      <hr v-if="divider" class="switch-container__divider">
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchCheckbox',
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    falseLabel: {
      type: String,
      default: ''
    },
    trueLabel: {
      type: String,
      default: ''
    },
    value: {
      type: [Boolean, String],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    important: {
      type: Boolean,
      default: false
    },
    dataAttr: {
      type: String,
      required: false,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    customClass: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'lg',
      validator: function (value) {
        return ['md', 'lg'].includes(value)
      }
    },
    divider: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    localValue: {
      get () {
        if (typeof this.value === 'string') {
          if (this.value.toLowerCase() === 'false') {
            return false
          }
          return !!this.value
        }
        return this.value
      },
      set (val) {
        if (!this.disabled) {
          this.$emit('input', val)
        }
      }
    },
    sliderClass () {
      const classes = ['switch__slider', 'switch__slider--' + this.size]
      !this.disabled && classes.push('switch__slider--disabled')
      return classes
    }
  },
  methods: {
    checked () {
      if (!this.disabled) {
        this.$emit('input', !this.value)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.switch-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 0.5rem;

  &--md {
    @include font(400 14px "Roboto");
  }

  &__divider {
    flex: 1;
    height: 2px;
    background-color: #e9ecef;
    border: none;
  }
}

.switch {
  position: relative;
  display: inline-block;
  flex: 0 0 auto;

  &--lg {
    width: rem(60px);
    height: rem(34px);
  }

  &--md {
    width: rem(40px);
    height: rem(20px);
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: rem(34px);

    &--disabled {
      cursor: default;
    }

    &:before {
      position: absolute;
      content: "";
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }

    &--lg {
      &:before {
        height: rem(26px);
        width: rem(26px);
        left: rem(30px);
        bottom: rem(4px);
      }
    }

    &--md {
      &:before {
        height: rem(16px);
        width: rem(16px);
        right: rem(2px);
        bottom: rem(2px);
      }
    }
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

input:checked:not(:disabled) + .switch__slider {
  background-color: #4caf50;
}

input:checked + .switch__slider {
  &--lg {
    &:before {
      -webkit-transform: translateX(-26px);
      -ms-transform: translateX(-26px);
      transform: translateX(-26px);
    }
  }

  &--md {
    &:before {
      -webkit-transform: translateX(-20px);
      -ms-transform: translateX(-20px);
      transform: translateX(-20px);
    }
  }
}

.important {
  width: rem(24px);
  height: rem(24px);
  border-radius: 50%;
  background-color: #b70b0b;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: rem(18px);
  font-weight: bold;
}
</style>
